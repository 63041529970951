                  
$(document).ready(function() {

  var countDownDate = new Date("Jun 01, 2024 12:00:00").getTime();
  var now = new Date().getTime();

  // Find the distance between now an the count down date
  var distance = countDownDate - now;

    var clock = $('.clock').FlipClock({
        clockFace: 'DailyCounter',
        autoStart: false,
        callbacks: {
            stop: function() {
                $('.clock').hide();
            }
        }
    });
            
    clock.setTime(distance/1000);
    clock.setCountdown(true);
    clock.start();

    $(".responsive-menu").click(function(){  
      $('.container-menu-responsive').toggle(); 
      
      document.getElementById("button-despleg").className="display-desactive responsive-menu animated bounceInRight flipOutY";
      document.getElementById("button-despleg-disable").className="display-active responsive-menu-disable animated flipInY";
      
  });    
  $(".responsive-menu-disable").click(function(){  
      $('.container-menu-responsive').toggle(); 
      
      document.getElementById("button-despleg").className="display-active responsive-menu animated bounceInRight flipInY";
      document.getElementById("button-despleg-disable").className="display-desactive responsive-menu-disable animated flipOutY";
      
  });
   
  $('a[href^="#"]').click(function() {
    var destino = $(this.hash);
    if (destino.length == 0) {
      destino = $('a[name="' + this.hash.substr(1) + '"]');
    }
    if (destino.length == 0) {
      destino = $('html');
    }
    $('html, body').animate({ scrollTop: destino.offset().top }, 900);
    return false;
  });

  if($( window ).width()<461){
    $('#clockCounter').hide();
  }
  else {
    $('#clockCounter').show();
  }

  $(window).resize(function() {
    if($( window ).width()<461){
      $('#clockCounter').hide();
    }
    else {
      $('#clockCounter').show();
    }

  });
   
});
